import SecureLS from "secure-ls";
import router from "../../../router";
import * as json2csvvue from "../../scripts/json2csv";
import * as csv2jsonvue from "../../scripts/csv2json";
import axios from "axios";
import { serverUrl } from "@/utils/env-variables-fns";
import {
  genericActionNoLoading,
  saveAction,
  getAction,
  genericActionNoMutation,
  genericActionNoMutationNoLoading,
  getActionLoading,
} from "@/utils/store-fns";

const ls = new SecureLS({ isCompression: false });

let actions = {
  /**
   * @desc Check if user is logged in, if the user exist in local storage, security token is checked in the server
   * this function is used in router guards
   */
  CHECKUSER: (context) => {
    return new Promise((resolve) => {
      let companyMainName = ls.get("ERPFCBUserMainCompanyName");
      let currentUser = ls.get(companyMainName + "ERPFCBUser");
      if (companyMainName && currentUser) {
        let payload = {
          token: JSON.parse(currentUser).token,
        };
        axios
          .post(`${serverUrl}/validate-token`, payload)
          .then((response) => {
            if (response.status === 200) {
              context.commit("SET_USER", JSON.parse(currentUser));
              context.commit("SET_LOGGED_IN", true);
              context.commit("SET_LOADED", true);
              resolve("Success");
            } else {
              context.commit("SET_LOADED", true);
              resolve("Logout");
            }
          })
          .catch((err) => {
            if (err.response && err.response.data === "Logout") {
              context.dispatch("LOGOUT_DATABASE");
              context.dispatch("LOGOUT");
              context.commit("SET_LOADED", true);
              router.push("/Login").catch();
              resolve("Logout");
            }
            context.commit("SET_LOADED", true);
            resolve("Logout");
          });
      } else {
        context.commit("SET_LOADED", true);
        resolve("Logout");
      }
    });
  },
  /**
   * @desc Export data into an csv file
   * @param {Object} payload.data - The json array with all the data to export
   * @param {Object} payload.name - The name of the file with the csv that will be downloaded.
   */
  EXPORTCSV: (context, payload) => {
    return new Promise((resolve) => {
      context.commit("SET_LOADING", true);
      var csv = json2csvvue.json2csv(payload.data, {
        fields: [{ _id: "test" }],
        output_csvjson_variant: false,
        separator: ",",
      });
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, payload.name + ".csv");
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", payload.name + ".csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      context.commit("SET_LOADING", false);
      resolve("Success");
    }).catch((err) => {
      context.commit("SET_LOADING", false);
    });
  },
  /**
   * @desc Import data given from csv into JSON array
   * @param {Object} payload - The json array with all the data to export
   * @param {Object} context - The current context of vuex
   */
  IMPORTCSV: (context, payload) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = (event) => {
        let csvContent = event.target.result;
        // Transforms csv content to object
        payload.file = csv2jsonvue.csv2json(csvContent, {
          parseNumbers: true,
          parseJSON: true,
        });
        genericActionNoLoading(
          context,
          payload,
          `${serverUrl}/import-data`
        ).then((response) => {
          resolve(response);
        });
      };
      reader.readAsText(payload.file);
    });
  },
  /**
   * @desc Import data given from csv into JSON array
   * @param {Object} payload - The json array with all the data to export
   * @param {Object} context - The current context of vuex
   */
  IMPORTLOCALCSV: (context, payload) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = (event) => {
        let csvContent = event.target.result;
        // Transforms csv content to object
        payload.file = csv2jsonvue.csv2json(csvContent, {
          parseNumbers: true,
          parseJSON: true,
        });
        resolve(payload);
      };
      reader.readAsText(payload.file);
    });
  },
  /**
   * @desc Login user with companyName, email and password
   * @param {Object} payload - The user information of the user who wants to log in
   * @param {Object} context - The current context of vuex
   */
  LOGIN: (context, payload) => {
    return new Promise((resolve) => {
      context.commit("SET_LOADING", true);
      axios
        .post(`${serverUrl}/login`, payload)
        .then((response) => {
          if (response.status === 200) {
            let currentUser = {
              token: response.data.token,
              companyMainName: response.data.companyMainName,
              name: response.data.name,
              email: response.data.email,
              permissions: response.data.permissions,
              validatedEmail: response.data.validatedEmail,
              tourCompleted: response.data.tourCompleted,
              betaMessageCompleted: response.data.betaMessageCompleted,
              pointOfSale: response.data.pointOfSale,
              ALMACENES: response.data.ALMACENES,
            };
            ls.set(
              response.data.companyMainName + "ERPFCBUser",
              JSON.stringify(currentUser)
            );
            ls.set("ERPFCBUserMainCompanyName", response.data.companyMainName);
            context.commit("SET_LOGGED_IN", true);
            context.commit("SET_USER", currentUser);
            context.commit("SET_LOADING", false);
            resolve("Success");
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          context.commit("SET_LOADING", false);
          resolve(error.response.data);
        });
    });
  },
  /**
   * @desc Logouts a specific user
   * @param {Object} payload - The user information of the user who wants to log in
   * @param {Object} context - The current context of vuex
   */
  LOGOUTSPECIFICUSER: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/logout-specific-user`,
        {},
        true
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Validates a user email
   * @param {Object} payload - The token of the email validation
   * @param {Object} context - The current context of vuex
   */
  COMPLETETUTORIAL: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/complete-tour`,
        "COMPLETE_TOUR"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Validates a user email
   * @param {Object} payload - The token of the email validation
   * @param {Object} context - The current context of vuex
   */
  COMPLETEBETAMESSAGE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/complete-beta-message`,
        "COMPLETE_BETA_MESSAGE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Creates a stripe session
   * @param {Object} payload - The plan selected
   * @param {Object} context - The current context of vuex
   */
  STRIPECHECKOUT: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/checkout-stripe`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Creates a stripe session to pay stamps
   * @param {Object} payload - The plan selected
   * @param {Object} context - The current context of vuex
   */
  STRIPECHECKOUTSTAMPS: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/checkout-stripe-stamps`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Creates a stripe session to pay users
   * @param {Object} payload - The plan selected
   * @param {Object} context - The current context of vuex
   */
  STRIPECHECKOUTUSERS: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/checkout-stripe-users`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Creates a stripe session to pay point of sales
   * @param {Object} payload - The plan selected
   * @param {Object} context - The current context of vuex
   */
  STRIPECHECKOUTPOS: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/checkout-stripe-pos`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Creates a stripe session to pay for custom cfdi documents
   * @param {Object} payload - The plan selected
   * @param {Object} context - The current context of vuex
   */
  STRIPECHECKOUTCFDIDOCUMENTS: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/checkout-stripe-cfdi-documents`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Creates a stripe session to pay for company name
   * @param {Object} payload - The plan selected
   * @param {Object} context - The current context of vuex
   */
  STRIPECHECKOUTCOMPANYNAME: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/checkout-stripe-company-name`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Creates a stripe session to pay warehouse
   * @param {Object} payload - The plan selected
   * @param {Object} context - The current context of vuex
   */
  STRIPECHECKOUTWAREHOUSE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/checkout-stripe-warehouses`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Creates a stripe portal session
   * @param {Object} payload
   * @param {Object} context
   */
  STRIPEPORTAL: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/portal-stripe`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Gets the period end of a subscription
   * @param {Object} payload
   * @param {Object} context
   */
  GETSUBSCRIPTIONEND: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutationNoLoading(
        context,
        payload,
        `${serverUrl}/get-subscription-end-time`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Registers a new user
   * @param {Object} payload - The new user information
   * @param {Object} context - The current context of vuex
   */
  REGISTERCOMPANY: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/register-company`
      ).then((response) => {
        if (response.success) {
          // Save new user to local storage
          let currentUser = {
            token: response.userInformation.token,
            name: response.userInformation.name,
            companyMainName: response.userInformation.companyMainName,
            email: response.userInformation.email,
            permissions: response.userInformation.permissions,
            validatedEmail: response.validatedEmail,
            tourCompleted: response.tourCompleted,
            betaMessageCompleted: response.betaMessageCompleted,
            ALMACENES: response.ALMACENES,
          };
          ls.set(
            response.userInformation.companyMainName + "ERPFCBUser",
            JSON.stringify(currentUser)
          );
          ls.set(
            "ERPFCBUserMainCompanyName",
            response.userInformation.companyMainName
          );
          context.commit("SET_USER", currentUser);
        }
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Resends the validation email
   */
  RESENDVALIDATIONEMAIL: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/resend-validation-email`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Validates a user email
   * @param {Object} payload - The token of the email validation
   * @param {Object} context - The current context of vuex
   */
  VALIDATEEMAIL: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/validate-email`,
        "VALIDATE_EMAIL"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Logouts the user and resets the user state
   */
  LOGOUT: (context) => {
    return new Promise((resolve) => {
      let logoutUser = {
        name: null,
        email: null,
        permissions: null,
        token: null,
      };
      ls.remove(ls.get("ERPFCBUserMainCompanyName") + "ERPFCBUser");
      ls.remove("ERPFCBUserMainCompanyName");
      context.commit("SET_LOGGED_IN", false);
      context.commit("SET_USER", logoutUser);
      context.commit("RESTORE_ACCOUNTING_STATES");
      context.commit("RESTORE_BANK_STATES");
      context.commit("RESTORE_BOARDS_STATES");
      context.commit("RESTORE_GLOBAL_STATES");
      context.commit("RESTORE_HUMAN_RESOURCES_STATES");
      context.commit("RESTORE_MAIN_STATES");
      context.commit("RESTORE_PRODUCTIVITY_STATES");
      context.commit("RESTORE_PURCHASES_STATES");
      context.commit("RESTORE_SALES_STATES");
      context.commit("RESTORE_TREASURY_STATES");
      router.push("/Login").catch();
      resolve("Success");
    });
  },
  /**
   * @desc Logouts the user and resets the user state
   */
  LOGOUT_DATABASE: (context) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/logout`,
        {},
        true
      ).then(() => {
        resolve("Success");
      });
    });
  },
  /**
   *
   * @desc Updates comany information
   */
  UPDATECOMPANYINFORMATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/update-company-information`,
        "CHANGE_COMPANY_INFORMATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Updates company documents configuration
   */
  UPDATECOMPANYINFORMATIONDOCUMENTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/update-company-information-documents`,
        "CHANGE_COMPANY_INFORMATION_DOCUMENTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the current company information from the database
   */
  UPDATECOMPANYINFORMATIONNOTIFICATIONS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/update-company-information-notifications`,
        "CHANGE_COMPANY_INFORMATION_NOTIFICATIONS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the current company information from the database
   */
  UPDATECOMPANYINVOICEINFORMATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/update-company-invoice-information`,
        "CHANGE_COMPANY_INVOICE_INFORMATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the current company information from the database
   */
  GETCOMPANYINFORMATION: (context) => {
    return new Promise((resolve) => {
      getActionLoading(
        context,
        `${serverUrl}/get-company-information`,
        "SET_COMPANY_INFORMATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Searches for the direction information from a zip code
   */
  SEARCHDIRECTIONINFORMATION: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/search-direction-information`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Sends recovery password email to the user
   */
  RECOVERYPASSWORD: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/recovery-password`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Resets a user password
   */
  RESETPASSWORD: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/reset-password`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the company SAT Link
   */
  GETSATLINK: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-link`,
        "SET_SAT_LINK"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the sales information
   */
  GETCOMPANYSALESDATE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-sales-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the sales articles information
   */
  GETCOMPANYSALESARTICLESDATE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-sales-articles-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the purchases information
   */
  GETCOMPANYPURCHASESDATE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-purchases-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the expenses information
   */
  GETCOMPANYEXPENSESDATE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-expenses-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the incomes information
   */
  GETCOMPANYINCOMES: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-incomes-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the articles list information
   */
  GETCOMPANYARTICLESLISTDATE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-articles-list-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the articles money list information
   */
  GETCOMPANYARTICLESMONEYLISTDATE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-articles-money-list-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },

  /**
   *
   * @desc Gets the articles list information
   */
  GETCOMPANYCLIENTSSLIST: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-clients-list-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the articles list information
   */
  GETCOMPANYPROVIDERSLIST: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-providers-list-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the personal sales and purcases
   */
  GETPERSONALSALESPURCHASES: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-personal-sales-purchases`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the manual movements
   */
  GETMANUALMOVEMENTSDATE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-manual-movements-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the cancellations
   */
  GETCANCELLATIONSDATE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-cancellations-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CONVERTRATE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutationNoLoading(
        context,
        payload,
        `${serverUrl}/exchange/convert-currency`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETALLAVAILABLEUNITS: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutationNoLoading(
        context,
        payload,
        `${serverUrl}/units/get-all-available-units`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETAVAILABLEUNITS: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutationNoLoading(
        context,
        payload,
        `${serverUrl}/units/get-available-units`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CONVERTUNIT: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutationNoLoading(
        context,
        payload,
        `${serverUrl}/units/convert-units`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the measures from rhe database
   */
  GETMEASURES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/units/get-measures`,
        "SET_MEASURES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new measure in the database
   */
  SAVEMEASURE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/units/save-measure`,
        "SAVE_MEASURES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a measure in the database
   */
  EDITMEASURE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/units/edit-measure`,
        "EDIT_MEASURES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes a measure from the database
   */
  DELETEMEASURES: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/units/delete-measures`,
        "DELETE_MEASURES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the company total value
   */
  GETCOMPANYTOTALVALUE: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/calculate-company-total-value`,
        "SET_COMPANY_TOTAL_VALUE",
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the user notifications
   */
  GETUSERNOTIFICATIONS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/get-user-notifications`,
        "SET_USER_NOTIFICATIONS",
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Marks a notification as read
   */
  MARKNOTIFICATIONASREAD: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/mark-notification-as-read`,
        "MARK_NOTIFICATION_AS_READ"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Marks all notifications as read
   */
  MARKALLNOTIFICATIONASREAD: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/mark-all-notifications-as-read`,
        "MARK_ALL_NOTIFICATION_AS_READ"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new company name in the database
   */
  SAVECOMPANYNAME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/save-new-company-name`,
        "SAVE_COMPANY_NAME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all company anmes from the database
   */
  GETCOMPANYNAMES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/get-company-names`,
        "SET_COMPANY_NAMES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a company name in the database
   */
  EDITCOMPANYNAME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/edit-company-name`,
        "EDIT_COMPANY_NAME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Activates a company name
   */
  ACTIVATECOMPANYNAME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/activate-company-name`,
        "ACTIVATE_COMPANY_NAME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Disables a company name
   */
  DISABLECOMPANYNAME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/disable-company-name`,
        "DISABLE_COMPANY_NAME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the transfers information
   */
  GETCOMPANYTRANSFERSDATE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-transfers-date`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the manual transfers
   */
  GETMANUALCOMPANYTRANSFERS: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-manual-company-transfers`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the transfers
   */
  GETCOMPANYTRANSFERS: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        {},
        `${serverUrl}/get-company-transfers`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets cause codes
   */
  GETCAUSECODES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/get-cause-codes`,
        "SET_CAUSE_CODES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
};

export default actions;
