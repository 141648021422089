import {
  saveActionComplete,
  getAction,
  genericActionNoMutation,
  saveAction,
} from "@/utils/store-fns";
import { serverUrl } from "@/utils/env-variables-fns";

let actions = {
  SYNCHDOCUMENTS: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/accounting/synch-documents`,
        "SYNCHDOCUMENTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETDOCUMENTS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-documents`,
        "SYNCHDOCUMENTS",
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETDOCUMENTXML: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-document-xml`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEINVOICE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-invoice`,
        "SAVE_INVOICE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEINVOICEMATERIALDELIVERY: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-invoice-material-delivery`,
        "SAVE_INVOICE_MATERIAL_DELIVERY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEPROFESSIONALSERVICESINVOICE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-invoice-professional-services-sale`,
        "SAVE_PROFESSIONAL_SERVICES_INVOICE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEINVOICECREDITNOTE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-invoice-credit-note`,
        "SAVE_INVOICE_CREDIT_NOTE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEINVOICEGLOBALCREDITNOTE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-global-invoice-credit-note`,
        "SAVE_GLOBAL_INVOICE_CREDIT_NOTE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEINVOICEPAYMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-invoice-payment`,
        "SAVE_INVOICE_PAYMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEINVOICEADVANCEDPAYMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-invoice-advanced-payment`,
        "SAVE_INVOICE_ADVANCED_PAYMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEPORTECARD: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-porte-card`,
        "SAVE_PORTE_CARD"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETINVOICEPDF: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-invoice-pdf`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETPROFESSIONALSERVICESINVOICEPDF: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-professional-services-invoice-pdf`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETCANCELLATIONINVOICEPDF: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-cancellation-invoice-pdf`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETCANCELLATIONPROFESSIONALSERVICESINVOICEPDF: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-professional-services-cancel-invoice-pdf`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SENDCFDI: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/send-invoice`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SENDPROFESSIONALSERVICESCFDI: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/send-professional-services-invoice`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETINVOICEXML: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-invoice-xml`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETCREDITNOTEPDF: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-credit-note-pdf`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SENDCFDICREDITNOTE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/send-credit-note`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETCREDITNOTEXML: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-credit-note-xml`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETPAYMENTXML: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-payment-xml`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETPAYMENTPDF: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-payment-pdf`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SENDCFDIPAYMENT: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/send-payment`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETPORTECARDXML: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-porte-card-xml`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETPORTECARDPDF: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-porte-card-pdf`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SENDCFDIPORTECARD: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/send-porte-card`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELINVOICE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/cancel-cfdi`,
        "CANCEL_INVOICE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELPROFESSIONALSERVICESINVOICE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/cancel-professional-services-cfdi`,
        "CANCEL_PROFESSIONAL_SERVICES_INVOICE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELDAILYINVOICE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/cancel-daily-cfdi`,
        "CANCEL_DAILY_INVOICE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELCREDITNOTE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/cancel-credit-note`,
        "CANCEL_CREDIT_NOTE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELPAYMENTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/cancel-payment`,
        "CANCEL_PAYMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELADVANCEDPAYMENTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/cancel-advanced-payment`,
        "CANCEL_ADVANCED_PAYMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELPORTECARD: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/cancel-porte-card`,
        "CANCEL_PORTE_CARD"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETACUSEXML: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-acuse`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETACUSECREDITNOTEXML: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-acuse-credit-note`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETACUSEPAYMENTXML: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-acuse-payment`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETACUSEPORTECARDXML: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/accounting/get-acuse-porte-card`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETWEIGHTUNITS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-weight-units`,
        "SET_WEIGHT_UNITS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETDANGEROUSMATERIALS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-dangerous-materials`,
        "SET_DANGEROUS_MATERIALS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETPACKAGINGS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-packagings`,
        "SET_PACKAGINGS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETTRASNPORTATIONPERMISSIONS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-transport-permissions`,
        "SET_TRANSPORTATION_PERMISSIONS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETTRANSPORTATIONCONFIGURATION: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-transport-configuration`,
        "SET_TRASNPORTATION_CONFIGURATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETTRAILERTYPE: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-trailer-type`,
        "SET_TRAILER_TYPE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETDAILYINVOICES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-daily-invoices`,
        "SET_DAILY_INVOICES",
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEDAILYINVOICE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-daily-invoice`,
        "SAVE_DAILY_INVOICE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all sales from the database
   */
  GETSALESXMLDIFFERENCES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-sales-xml-differences`,
        "SET_SALES_XML_DIFFERENCES",
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
          warehouses: payload.warehouses,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETCONTABLEACCOUNTS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-contable-accounts`,
        "SET_CONTABLE_ACCOUNTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVECONTABLEACCOUNTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-contable-accounts`,
        "SET_CONTABLE_ACCOUNTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all cash sales policies from the database
   */
  GETCASHSALESPOLICIES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-cash-sales-policies`,
        "SET_CASH_SALES_POLICIES",
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all porte cards from the database
   */
  GETPORTECARDS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/accounting/get-porte-cards`,
        "SET_PORTE_CARDS",
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEPORTECARDNOSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/accounting/save-porte-card-no-sale`,
        "SAVE_PORTE_CARD_NO_SALE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
};

export default actions;
