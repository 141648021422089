import { saveAction, getAction } from "@/utils/store-fns";
import { serverUrl } from "@/utils/env-variables-fns";

let actions = {
  /**
   *
   * @desc Adds new bank accounts in the database
   */
  SAVEBANKACCOUNTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/save-bank-accounts`,
        "ADD_BANK_ACCOUNTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Adds new bank accounts in the database
   */
  UPDATEBANKACCOUNTCONTABLEACCOUNTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/update-bank-account-contable-accounts`,
        "UPDATE_BANK_ACCOUNT_CONTABLE_ACCOUNTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all bank accounts in the database
   */
  GETBANKACOUNTS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/banks/get-bank-accounts`,
        "SET_BANK_ACCOUNTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEBANKACCOUNTMOVEMENTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/save-bank-account-movements`,
        "ADD_BANK_ACCOUNT_MOVEMENTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SYNCHBANKACCOUNTMOVEMENTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/synch-bank-account-movements`,
        "SYNCH_BANK_ACCOUNT_MOVEMENTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEBANK: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/save-bank`,
        "SAVE_BANK"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETBANKS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/banks/get-banks`, "SET_BANKS").then(
        (response) => {
          resolve(response);
        }
      );
    });
  },
  /**
   *
   * @desc Edits a banks in the database
   */
  EDITBANKS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/edit-banks`,
        "EDIT_BANKS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEBANKMOVEMENTTYPE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/save-bank-movement-types`,
        "SAVE_BANK_MOVEMENT_TYPE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc gets bank movement types from the database
   * @param context the current store context
   * @param payload the payload sended from the component
   */
  GETBANKMOVEMENTTYPES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/banks/get-bank-movement-types`,
        "SET_BANKS_MOVEMENT_TYPE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc synchs the balance of all the automatic accounts
   * @param context the current store context
   * @param payload the payload sended from the component, in this case the id of the bank account
   */
  SYNCHBANKACCOUNTBALANCES: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/synch-bank-account-balance`,
        "SYNCH_BANK_ACCOUNT_BALANCES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc consolidates bank movements
   * @param context the current store context
   * @param payload the payload sended from the component, in this case an array of bank movements
   */
  CONSOLIDATEBANKMOVEMENTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/consolidate-bank-account-movements`,
        "CONSOLIDATE_BANK_MOVEMENTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc cancels a bank movement
   * @param context the current store context
   * @param payload the payload sended from the component, in this case the id of the movement
   */
  CANCELMOVEMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/cancel-bank-account-movement`,
        "CANCEL_MOVEMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc changes the show in account status of a bank movement
   * @param context the current store context
   * @param payload the payload sended from the component, in this case the id of the movement
   */
  CHANGESHOWINACCOUNTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/change-show-in-accounts`,
        "CHANGE_SHOW_IN_ACCOUNTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },

  /**
   *
   * @desc changes the show in account status of multiple bank movements
   * @param context the current store context
   * @param payload the payload sended from the component, in this case the id of the movement
   */
  CHANGESHOWINACCOUNTSMULTIPLE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/banks/change-show-in-accounts-multiple`,
        "CHANGE_SHOW_IN_ACCOUNTS_MULTIPLE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
};

export default actions;
