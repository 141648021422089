let state = {
  incomeDocuments: [],
  paymentDocuments: [],
  salaryDocuments: [],
  expenseDocuments: [],
  transferDocuments: [],
  invoices: [],
  creditNotesInvoices: [],
  paymentInvoices: [],
  porteCards: [],
  weightUnits: [],
  dangerousMaterials: [],
  packagings: [],
  transportationPermissions: [],
  transportationConfiguration: [],
  trailerTypes: [],
  dailyInvoices: [],
  salesXMLDifferences: [],
  contableAccounts: [],
  cashSalesPolicies: [],
};

export default state;
