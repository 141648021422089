import {
  saveAction,
  saveActionComplete,
  saveActionCompleteResponse,
  getAction,
  genericActionNoMutation,
  saveActionCompleteResponseContext,
} from "@/utils/store-fns";
import { serverUrl } from "@/utils/env-variables-fns";

let actions = {
  /**
   *
   * @desc Saves a new prices list in the database and saves given price list in the current sesion of the user
   */
  SAVEPRICELIST: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-price-list`,
        "SAVE_PRICE_LIST"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a price list in the database
   */
  EDITPRICELIST: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-price-list`,
        "EDIT_PRICE_LIST"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes a price list in the database
   */
  DELETERICELIST: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/delete-price-list`,
        "DELETE_PRICE_LIST"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new prices list cost in the database
   */
  SAVEPRICESLISTCOSTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-price-list-costs`,
        "SAVE_PRICE_LIST_COSTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new prices list discounts in the database
   */
  SAVEPRICESLISTDISCOUNTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-price-list-disconts`,
        "SAVE_PRICE_LIST_DISCOUNTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all prices lists from the datbase
   */
  GETPRICESLISTS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-price-lists`,
        "SET_PRICES_LIST"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new prices list articles in the database and saves given prices list articles in the current sesion of the user
   */
  UPDATEPRICELISTARTICLES: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/update-price-list-articles`,
        "UPDATE_PRICE_LIST"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all clients from the datbase
   */
  GETCLIENTS: (context) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/sales/get-clients`, "SET_CLIENTS").then(
        (response) => {
          resolve(response);
        }
      );
    });
  },
  /**
   *
   * @desc Saves a new client in the database
   */
  SAVECLIENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-client`,
        "SAVE_CLIENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a client in the database
   */
  EDITCLIENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-client`,
        "EDIT_CLIENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the last sale number of sale in the database
   */
  GETLASTSALENUMBER: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-last-sale-number`,
        "SET_LAST_SALE_NUMBER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all quotes from the database
   */
  GETQUOTES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/sales/get-quotes`, "SET_QUOTES", {
        startDate: payload.startDate,
        endDate: payload.endDate,
        warehouses: payload.warehouses,
      }).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new quote in the database
   */
  SAVEQUOTE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-sale`,
        "ADD_QUOTE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a quote in the database
   */
  EDITQUOTE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-quote`,
        "EDIT_QUOTE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes a request order in the database
   */
  DELETEQUOTES: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/delete-quotes`,
        "DELETE_QUOTES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Confirms a quote in the database
   */
  CONFIRMQUOTE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/confirm-quote`,
        "CONFIRM_QUOTE"
      ).then((response) => {
        context.dispatch("GETCOMISSIONS", { type: 1 });
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new sale in the database
   */
  SAVESALE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-sale`,
        "ADD_SALE"
      ).then((response) => {
        context.dispatch("GETCOMISSIONS", { type: 1 });
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new sale in the database
   */
  SAVEPROFESSIONALSERVICESSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-profesional-services-sale`,
        "ADD_PROFESSIONAL_SERVICES_SALE"
      ).then((response) => {
        context.dispatch("GETCOMISSIONS", { type: 1 });
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Cancels a sale in the database
   */
  CANCELSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/cancel-sale`,
        "CANCEL_SALE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELPROFESSIONALSERVICESSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/cancel-professional-services-sale`,
        "CANCEL_PROFESSIONAL_SERVICES_SALE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all sales from the database
   */
  GETSALES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/sales/get-sales`, "SET_SALES", {
        startDate: payload.startDate,
        endDate: payload.endDate,
        warehouses: payload.warehouses,
        client: payload.client,
      }).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all professional services sales from the database
   */
  GETPROFESSIONALSERVICESSALES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-professional-services-sales`,
        "SET_PROFESSIONAL_SERVICES_SALES",
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
          warehouses: payload.warehouses,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a sale in the database
   */
  EDITSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-quote`,
        "EDIT_SALE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a professional services sale in the database
   */
  EDITPROFESSIONALSERVICESSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-professional-services-sale`,
        "EDIT_PROFESSIONAL_SERVICES_SALE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Sends material to the client
   */
  SENDMATERIAL: (context, payload) => {
    return new Promise((resolve) => {
      saveActionCompleteResponseContext(
        context,
        payload,
        `${serverUrl}/sales/send-material`,
        "SEND_MATERIAL"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  VALIDATESALEMATERIALSUBSTRACTION: (context, payload) => {
    return new Promise((resolve) => {
      saveActionCompleteResponseContext(
        context,
        payload,
        `${serverUrl}/sales/validate-sale-material-substraction`,
        "VALIDATE_SALE_MATERIAL_SUBSTRACTION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Changes the status of a material delivery
   */
  DELIVERALLMATERIAL: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/confirm-all-material-delivery`,
        "DELIVER_ALL_MATERIAL"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Changes the status of a driver material delivery
   */
  DELIVERALLDRIVERMATERIAL: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales//confirm-all-driver-material-delivery`,
        "DELIVER_ALL_DRIVER_MATERIAL"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Save the sign of a material delivery
   */
  SAVEMATERIALDELIVERYSIGN: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-material-delivey-sign`,
        "SAVE_MATERIAL_DELIVERY_SIGN"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a driver material delivery
   */
  SAVEDRIVERMATERIALDELIVERY: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-driver-material-delivey-sign`,
        "SAVE_DRIVER_MATERIAL_DELIVERY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the last clarification number of sale in the database
   */
  GETLASTCLARIFICATIONNUMBER: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-last-clarification-number`,
        "SET_LAST_CLARIFICATION_NUMBER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the last credit note number of sale in the database
   */
  GETLASTCREDITNOTENUMBER: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-last-credit-note-number`,
        "SET_LAST_CREDIT_NOTE_NUMBER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all discount concepts from the datbase
   */
  GETDISCOUNTCONCEPTS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-discount-concepts`,
        "SET_DISCOUNT_CONCEPTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new discount concept in the database
   */
  SAVEDISCOUNTCONCEPT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-discount-concept`,
        "SAVE_DISCOUNT_CONCEPTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new clarification in the database
   */
  SAVECLARIFICATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-clarification`,
        "SAVE_CLARIFICATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Cancels a clarification in the database
   */
  CANCELCLARIFICATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/cancel-clarification`,
        "CANCEL_CLARIFICATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new credit note in the database
   */
  SAVECREDITNOTE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-credit-note`,
        "SAVE_CREDIT_NOTE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new global credit note in the database
   */
  SAVEGLOBALCREDITNOTE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-global-credit-note`,
        "SAVE_GLOBAL_CREDIT_NOTE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Cancels a credit note in the database
   */
  CANCELCREDITNOTEDOCUMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/cancel-credit-note`,
        "CANCEL_CREDIT_NOTE_DOCUMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Cancels a GLOBAL credit note in the database
   */
  CANCELGLOBALCREDITNOTEDOCUMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/cancel-global-credit-note`,
        "CANCEL_GLOBAL_CREDIT_NOTE_DOCUMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets the last income number of sale in the database
   */
  GETLASTINCOMENUMBER: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-last-income-number`,
        "SET_LAST_INCOME_NUMBER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all sat cfdi uses from the database
   */
  GETSATCFDIUSES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-cfdi-uses-sat`,
        "SET_SAT_CFDI_USES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all sat regimenes from the database
   */
  GETSATREGIMENES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-regimenes-sat`,
        "SET_SAT_REGIMENES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a commercial business in the database
   */
  SAVECOMMERCIALBUSINESS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-commercial-business`,
        "SAVE_COMMERCIAL_BUSINESS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a commercial business in the database
   */
  EDITCOMMERCIALBUSINESS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-commercial-business`,
        "EDIT_COMMERCIAL_BUSINESS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all commercial business from the database
   */
  GETCOMMERCIALBUSINESS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-commercial-business`,
        "SET_COMMERCIAL_BUSINESS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new payment type in the database
   */
  SAVEPAYMENTTYPE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-payment-type`,
        "SAVE_PAYMENT_TYPE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all payment types from the database
   */
  GETPAYMENTTYPES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-payment-types`,
        "SET_PAYMENT_TYPES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all pos from the database
   */
  GETPOS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/sales/get-pos`, "SET_POS", {
        startDate: payload.startDate,
        endDate: payload.endDate,
        warehouses: payload.warehouses,
      }).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all pos from the database
   */
  GETPOSNOSALES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/sales/get-pos-no-sales`, "SET_POS", {
        warehouses: payload.warehouses,
      }).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new pos in the database
   */
  SAVEPOS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-pos`,
        "SAVE_POS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a pos in the database
   */
  EDITPOS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-pos`,
        "EDIT_POS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Opens a pos in the database
   */
  OPENPOS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/open-pos`,
        "OPEN_POS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Opens agains the last corte in a pos
   */
  OPENAGAINPOS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/open-again-pos`,
        "OPEN_AGAIN_POS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new sale of point of sale in the database
   */
  SAVEPOSSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveActionCompleteResponseContext(
        context,
        payload,
        `${serverUrl}/sales/save-sale-pos`,
        "SAVE_POS_SALE"
      ).then((response) => {
        context.dispatch("GETCOMISSIONS", { type: 1 });
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Closes a pos in the database
   */
  CLOSEPOS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/close-pos`,
        "CLOSE_POS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Authorizes a corte in the database
   */
  AUTHORIZECORTE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/authorize-corte`,
        "AUTHORIZE_CORTE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc No authorizes a corte in the database
   */
  NOAUTHORIZECORTE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/no-authorize-corte`,
        "NO_AUTHORIZE_CORTE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Add manual movement to cortes
   */
  ADDMANUALMOVEMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/add-manual-movement`,
        "ADD_MANUAL_MOVEMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a missing sale in the database
   */
  SAVEMISSINGSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveActionCompleteResponse(
        context,
        payload,
        `${serverUrl}/sales/save-missing-sale`,
        "SAVE_MISSING_SALE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a quote in a pos in the database
   */
  SAVEQUOTEPOS: (context, payload) => {
    return new Promise((resolve) => {
      saveActionCompleteResponse(
        context,
        payload,
        `${serverUrl}/sales/save-quote-pos`,
        "SAVE_QUOTE_POS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes a quote in a pos in the database
   */
  DELETEQUOTEPOS: (context, payload) => {
    return new Promise((resolve) => {
      saveActionCompleteResponse(
        context,
        payload,
        `${serverUrl}/sales/delete-quote-pos`,
        "DELETE_QUOTE_POS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes a quote in a pos in the database
   */
  CONFIRMQUOTEPOS: (context, payload) => {
    return new Promise((resolve) => {
      saveActionCompleteResponse(
        context,
        payload,
        `${serverUrl}/sales/confirm-quote-pos`,
        "CONFIRM_QUOTE_POS"
      ).then((response) => {
        resolve(response);
      });
    });
  },

  /**
   *
   * @desc Deletes a missing sale in the database
   */
  DELETEMISSINGSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/delete-missing-sale`,
        "DELETE_MISSING_SALE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a secret in the database
   */
  SAVESECRET: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-secret`,
        "SAVE_SECRET"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all secrets from the database
   */
  GETSECRETS: (context) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/sales/get-secrets`, "SET_SECRETS").then(
        (response) => {
          resolve(response);
        }
      );
    });
  },
  /**
   *
   * @desc Saves a secret in the database
   */
  EDITSECRET: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-secret`,
        "EDIT_SECRET"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Delets secrets in the database
   */
  DELETESECRETS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/delete-secrets`,
        "DELETE_SECRETS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc searches for a secret in the database
   */
  SEARCHSECRET: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/sales/search-secret`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Add one to the print or send counter in a quote or sale
   */
  PRINTORSENDSALESQUOTES: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/sales/print-or-send-sale-quote`,
        "PRINT_OR_SEND_SALE_QUOTE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc searches for a client unlock in the database
   */
  SEARCHUNLOCKCLIENT: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/sales/search-unlock-client`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a client unlock in the database
   */
  SAVECLIENTUNLOCK: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-client-unlock`,
        "SAVE_CLIENT_UNLOCK"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all secrets from the database
   */
  GETCLIENTSUNLOCKS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-clients-unlocks`,
        "SET_CLIENTS_UNLOCKS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Delets secrets in the database
   */
  DELETECLIENTSUNLOCKS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/delete-clients-unlocks`,
        "DELETE_CLIENTS_UNLOCKS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc searches for a warehouse unlock in the database
   */
  SEARCHUNLOCKWAREHOUSE: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/sales/search-unlock-warehouse`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a warehouse unlock in the database
   */
  SAVEWAREHOUSEUNLOCK: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-warehouse-unlock`,
        "SAVE_WAREHOUSE_UNLOCK"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all secrets from the database
   */
  GETWAREHOUSESUNLOCKS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-warehouses-unlocks`,
        "SET_WAREHOUSES_UNLOCKS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Delets secrets in the database
   */
  DELETEWAREHOUSESUNLOCKS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/delete-warehouses-unlocks`,
        "DELETE_WAREHOUSES_UNLOCKS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  EDITMATERIALDELIVERYSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-material-delivery-sale`,
        "EDIT_MATERIAL_DELIVERY_SALE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  EDITPARTIALSALE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-partial-sale`,
        "EDIT_PARTIAL_SALE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  EDITPARTIALSALEORDER: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-partial-sale-order`,
        "EDIT_PARTIAL_SALE_ORDER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new services in the database and saves given service in the current sesion of the user
   */
  SAVESERVICE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-professional-service`,
        "SAVE_SERVICE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all services from the database
   */
  GETSERVICES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-professionaL-services`,
        "SET_SERVICES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a service in the database
   */
  EDITSERVICE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/edit-professional-service`,
        "EDIT_SERVICE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a client balance in the database
   */
  ADDCLIENTBALANCEENTRY: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/add-client-manual-balance-entry`,
        "ADD_CLIENT_BALANCE_ENTRY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits the credit of a client
   */
  EDITCLIENTCREDIT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/edit-client-credit`,
        "EDIT_CLIENT_CREDIT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Remove sales back order
   */
  REMOVESALEBACKORDER: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/remove-sale-back-order`,
        "SAVE_CLARIFICATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets client credit groups
   */
  GETCLIENTCREDITGROUP: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/sales/get-client-credit-group`,
        "SET_CLIENT_CREDIT_GROUP"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves client credit groups
   */
  SAVECLIENTCREDITGROUP: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/save-client-credit-group`,
        "SAVE_CLIENT_CREDIT_GROUP"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Updates a client credit groups
   */
  UPDATECLIENTCREDITGROUP: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/update-client-credit-group`,
        "UPDATE_CLIENT_CREDIT_GROUP"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes a client credit groups
   */
  DELETECLIENTCREDITGROUP: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/sales/delete-client-credit-group`,
        "DELETE_CLIENT_CREDIT_GROUP"
      ).then((response) => {
        resolve(response);
      });
    });
  },
};

export default actions;
